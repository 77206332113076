import * as React from "react"
import { Link } from "gatsby-plugin-intl";
import Layout from "../components/Layout.jsx";

const NotFoundPage = ({location}) => {
  return (
<Layout location={location}>
<div className="page-wrap d-flex flex-row align-items-center">
    <div className="container">
        <div className="row justify-content-center my-5">
            <div className="col-md-12 text-center">
                <span className="display-1 d-block">404</span>
                <div className="mb-4 lead">The page you are looking for was not found.</div>
                <Link to="/" className="btn btn-link">Back to Home</Link>
            </div>
        </div>
    </div>
</div>
</Layout>
  )
}

export default NotFoundPage
